<template>
    <div>
        <ValidationObserver ref="updateForm">
        <div class="row data-form">

            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{poolName}}</h6>
                </div>
            </div>
            <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                <ValidationProvider name="courseSearch" rules="" v-slot="{valid, errors}">
                    <b-form-group :label="$t('search_course')">
                        <course-auto-complete :query="searchResultText" v-model="searchResult" :validate-error="errors[0]"></course-auto-complete>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                <b-button class="mt-5" variant="primary" @click="addCourse">{{$t('save')}}</b-button>
            </div>
            <div class="col-12 col-md-12">
                <b-table striped hover :items="courseItems" :fields="fields">
                    <template #cell(space)="data">
                        <b-button size="sm" variant="danger" @click="deleteCourse(data)">{{$t('delete').toUpper()}}</b-button>
                    </template>
                </b-table>
            </div>

        </div>
        </ValidationObserver>
    </div>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import ElectiveCourseService from '@/services/ElectiveCourseService';
    import qs from 'qs';
    import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';

    export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CourseAutoComplete
    },
    props: {
        id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            poolName: null,
            searchResult: null,
            searchResultText: null,

            fields: [
                {
                    key: 'space',
                    label: ''
                },
                {
                    key: 'code',
                    label: this.$t('course_code')
                },
                {
                    key: 'name',
                    label: this.$t('course_name')
                },
                {
                    key: 'language',
                    label: this.$t('course_language')
                },
                {
                    key: 'credit',
                    label: this.$t('credit')
                },
            ],
            courseItems: []
        }
    },
    metaInfo() {
        return {
            title: this.$t("elective_pools_title")
        }
    },
    methods: {
        getFormData() {
            if(this.id != null){
                let config = {
                    params: {
                        filter: {
                            pool_id: this.id
                        },
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                ElectiveCourseService.getAll(config)
                                     .then(response => {
                                         let data = response.data.data
                                         this.poolName = data.length > 0 ? data[0].pool.name : ''
                                         this.courseItems = []

                                         data.forEach(item => {
                                             this.courseItems.push({
                                                 code: item.course.code,
                                                 name: item.course.name,
                                                 language: item.course.language,
                                                 credit: item.course.credit,
                                                 id: item.course_id
                                             })
                                         })
                                     })
            }
        },

        async addCourse(){
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let courseData = {
                    pool_id: this.id,
                    course_id: this.searchResult
                }
                ElectiveCourseService.store(courseData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.getFormData()
                }).catch(e => {
                    if (e.status == '406') {
                        if (e.data.message) {
                            this.$refs.updateForm.errors.name.push(this.$t('api.' + e.data.message));
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.pool_id) {
                            this.$refs.updateForm.errors.courseSearch.push(e.data.errors.pool_id[0]);
                        }
                        if (e.data.errors.course_id) {
                            this.$refs.updateForm.errors.courseSearch.push(e.data.errors.course_id[0]);
                        }
                    }
                })
            }
        },

        deleteCourse(item) {
            this.$swal({
                title: this.$t('attention'),
                text: this.$t('elective_pools_course_delete_confirm_text'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if(response.isConfirmed == true) {
                    ElectiveCourseService.remove(this.id,item.item.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getFormData()
                    })
                }
            })
        }
    },

    created() {

    },

    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
